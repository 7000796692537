<template>
  <Loading v-if="show" :type="3" />
  <div id="pay-result" v-else>
    <div class="success" v-if="states == 1">
      <div class="icon">
        <svg class="icon icon-reset-pay-result" aria-hidden="true">
          <use xlink:href="#icon-chenggong2"></use>
        </svg>
      </div>
      <div class="title">
        订单支付成功
      </div>
    </div>
    <div v-else class="success">
      <div class="icon">
        <svg class="icon icon-reset-pay-result" aria-hidden="true">
          <use xlink:href="#icon-shibai"></use>
        </svg>
      </div>
      <div class="title">
        订单支付失败
      </div>
    </div>
    <div class="success-des"></div>
    <div class="operating">
      <van-button type="primary" @click="goSubPageR('pay-list')">返回亿师傅</van-button>
      <!-- <van-button type="info"></van-button> -->
    </div>
  </div>
</template>
<script>
export default {
  props: {
    title: {
      type: String,
      default: "",
    },
    description: {
      type: String,
      default: "",
    },
    operating: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      show: true,
      states: 1,
    };
  },
  created() {
    console.log(this.$route);
    this.getPayState();
  },
  methods: {
    getPayState() {
      this.$http
        .get(
          `api/v2/worker/security_deposit/notify/return?out_trade_no=${this.$route.out_trade_no}`
        )
        .then((res) => {
          res.data.code == 0 ? (this.states = 1) : (this.states = 2);
          this.show = false;
        });
    },
  },
};
</script>

<style lang="less">
#pay-result {
  height: 100vh;
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  .icon-reset-pay-result {
    width: 9rem;
    height: 9rem;
  }
  .success {
    background: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  .title {
    padding: 1rem 0;
    font-size: 2.5rem;
    font-weight: 600;
  }
  .operating {
    padding: 4rem 0;
    display: flex;
    justify-content: space-between;
  }
}
</style>